import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BootstrapService } from '@core/service/bootstrap.service';
import { Observable } from 'rxjs';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { LayoutService } from './layout/services/layout.service';
import { httpAuthInterceptor, httpErrorInterceptor } from '@core/fInterceptors/auth.interceptor';

function initializeAppFactory(bootstrapService: BootstrapService): () => Observable<boolean> {
  return () => bootstrapService.loadSetting();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    provideHttpClient(withInterceptors([httpAuthInterceptor,httpErrorInterceptor])),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [BootstrapService, LayoutService],
      multi: true
  },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
