
import { Router, NavigationEnd, RouterLink } from '@angular/router';
import {
  Component,
  OnInit,
  HostListener,
  OnDestroy,
  Input,
  inject,
} from '@angular/core';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { IMenuItem, menu_items } from './menu-items';
import { SidebarBase } from '../base/sidebar.base';
import { UserInformation } from '@core/models/user-information';
import { CommonServicesService } from '@core/service/common-services.service';
import { AsyncPipe, NgClass, NgStyle, SlicePipe } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatMenuModule } from '@angular/material/menu';
import { SliderComponent } from 'app/custom-components/slider/slider.component';
import { SharedStateService } from '@shared/services/shared-state.service';
import { Observable } from 'rxjs';
import { AuthService } from '@core/service/auth.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [NgStyle, NgClass, SlicePipe,
    AsyncPipe, NgScrollbarModule, MatMenuModule,
    SliderComponent, RouterLink]
})
export class SidebarComponent extends SidebarBase implements OnInit, OnDestroy {

  stateService = inject(SharedStateService);
  authService = inject(AuthService);
  orgSideItems: Observable<IMenuItem[]>;
  user: UserInformation = JSON.parse(localStorage.getItem('loggedUser'));
  commonService = inject(CommonServicesService);
  level1Org: boolean = localStorage.getItem('ParentOrgID') === localStorage.getItem('orgId');
  showSearchInSidebar: boolean = false;
  constructor(private router: Router) {
    super();
    const currenturl = router?.url.split('?')[0];
    this.level1Menu = currenturl.split('/')[1];
    this.level2Menu = currenturl.split('/')[2];
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd), takeUntil(this.unsubscribeAll$)).
      subscribe((event: NavigationEnd) => {
        const currenturl = event?.url.split('?')[0];
        this.level1Menu = currenturl.split('/')[1];
        this.level2Menu = currenturl.split('/')[2];
        this.renderer.removeClass(document.body, 'overlay-open');
      });
  }

  ngOnInit() {
    this.initLeftSidebar();
    this.side_items_init();
    this.watchOrgChange();
    this.toggleSearchDisplay();
  }
  toggleSearchDisplay() {
    this.showSearchInSidebar = localStorage.getItem('ParentOrgID') === localStorage.getItem('orgId');
  }
  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(document.body, 'overlay-open');
    }
  }


  ngOnDestroy() {
    this.unsubscribeAll$?.next();
    this.unsubscribeAll$?.complete()
    this.unsubscribeAll$?.unsubscribe();
  }

  ToogleOrganization(organization) {
    localStorage.setItem('orgId', organization?.OrganisationId);
    localStorage.setItem('orgType', organization?.OrganizationTypeID);
    localStorage.setItem('OrgName', organization?.Name);
    if (localStorage.getItem('ParentOrgID') !== organization.OrganisationId) {
      this.level1Org = false;
      return
    }
    this.side_items_init();
    this.level1Org = true;
    this.router.navigate(['/dashboard'])
  }


  SwitchToAdmin() {
    localStorage.removeItem('orgId');
    localStorage.removeItem('orgType');
    localStorage.removeItem('OrgName');
    localStorage.removeItem('ParentOrgID');
    localStorage.setItem("adminManageType", '1');
    this.authService.adminManageType.next(1);
  }

  NavigateToProfile() {
    const [id, type] = [localStorage.getItem('orgId'), localStorage.getItem('orgType')];
    this.router.navigate(['/organization/organization-details'], { queryParams: { id, type } })
  }

  side_items_init() {
    this.OrganizationName = localStorage.getItem('OrgName')
    const payload = {
      OrganisationId: localStorage.getItem('orgId'),
      OrganizationType: localStorage.getItem('orgType')
    }
    this.orgSideItems = this.stateService.FetchOrganizationDashbordCount(payload).pipe(distinctUntilChanged(), map((data) => {
      
      const lookupResponse = new Map(data.map((item) => [item.Ref, item]));
      const enabled_items =  menu_items.filter(i=> lookupResponse.has(i.Ref));
      return enabled_items.map((item => {
        if (item.sub_items?.length) {
          const enabled_sub_items = item.sub_items.filter(it=> lookupResponse.has(it.Ref));
          item.sub_items = enabled_sub_items.map((sub) => {
            if (lookupResponse.has(sub.Ref)) {
              return { ...sub, ...lookupResponse.get(sub.Ref), customLabel: lookupResponse.get(sub.Ref).Module }
            }
            return sub
          })
        }
        if (lookupResponse.has(item.Ref)) {
          return { ...item, ...lookupResponse.get(item.Ref), customLabel: lookupResponse.get(item.Ref).Module }
        }
        return item;
      }))
    }))
    if (localStorage.getItem('ParentOrgID') !== payload.OrganisationId) {
      this.level1Org = false;
      return
    }
    this.level1Org = true;
  }

  watchOrgChange() {
    this.commonService.Refresh.pipe(tap((values) => {
      if (!values) {
        return;
      };
      this.side_items_init();
    })).subscribe()
  }

}
