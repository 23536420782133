export interface IMenuItem{
      label:string;
      customLabel:string;
      module:string;
      path:string;
      icon:string;
      activeLevel:number;
      linkClass?:string;
      level2Menu?:string;
      submenu?:Array<IMenuItem>;
      default?:boolean,
      disableForAdmin?:boolean
      }
      
      export const menu_items:Array<IMenuItem>=[
            {
                  label:'Home',
                  customLabel:null,
                  path:'/dashboard',
                  module:'dashboard',
                  icon:'fas fa-home',
                  activeLevel:1,
                  default:true
            },
            {
                  label:'Saved Searches',
                  customLabel:null,
                  path:'/saved-search/listing',
                  module:'saved-search',
                  icon:'fas fa-save',
                  activeLevel:1,
                  default:true
            },
            {
                  label:'Useropportunty',
                  customLabel:'UserOpportuntyLabel',
                  path:'/opportunity/individual-opportunities',
                  module:'opportunity',
                  icon:'fas fa-star',
                  activeLevel:1,
            },
            {
                  label:'UserDonation',
                  customLabel:'UserDonationLabel',
                  path:'/donation/individual-donations',
                  module:'donation',
                  icon:'fas fa-donate',
                  activeLevel:1,
            },
            {
                  label:'UserClasses',
                  customLabel:'UserClassesLabel',
                  path:'/classes/individual-classes',
                  module:'classes',
                  icon:'fas fa-chalkboard',
                  activeLevel:1,
            },
            {
                  label:'UserServices',
                  customLabel:'UserServicesLabel',
                  path:'/services/individual-services',
                  module:'services',
                  icon:'fas fa-wrench',
                  activeLevel:1,
            },
            {
                  label:'Userforums',
                  customLabel:'UserForumsLabel',
                  path:'/forums/individual-forums',
                  module:'forums',
                  icon:'fas fa-list',
                  activeLevel:1,
            },
            {
                  label:'UserCOE',
                  customLabel:'UserCOELabel',
                  path:'/calendar/individual-events',
                  module:'calendar',
                  icon:'fas fa-calendar',
                  activeLevel:1,
            }
      ]