@if (authService.LoggedUserValue?.Token) {
<app-header />
}@else {
<app-header-public />
}
@if (userType) {
@switch (userType) {
@case (1) {
@if (authService.adminManageType | async ; as manageType) {
@switch (manageType) {
@case (2) {
<app-sidebar />
}
@case (3) {
<app-individual-sidebar />
}
@default {
<app-admin-sidebar />
}
}
}
}
@case (2) {
<app-sidebar />
}
@case (3) {
<app-individual-sidebar />
}
}
}

<app-right-sidebar />
<router-outlet></router-outlet>