<!-- Right Sidebar -->
@if (this.layoutService.visible) {
<aside id="rightsidebar" class="right-sidebar" [ngClass]="this.layoutService.visible ? 'open' : 'close'"
  [ngStyle]="{ 'max-height' : 180+'px', 'max-width' : maxWidth+'px'}" (clickOutside)="onClickedOutside($event)">
  <div class="rightSidebarClose"><i class="far fa-times-circle" (click)="this.layoutService.visible=false"></i></div>
  <div class="demo-skin" [ngStyle]="{'position' : 'relative', 'max-height' : 160 +'px', 'max-width' : maxWidth+'px'}">

    <div class="rightSetting">
      <p>SKINS</p>
      <ul class="demo-choose-skin choose-theme list-unstyled">
        <li data-theme="white" [ngClass]="{'actived': selectedBgColor === 'white'}" (click)="selectTheme('white')">
          <div class="white-theme white-theme-border">
           @if(selectedBgColor ==='white'){
            <i class="fa fa-check text-dark"></i>
           }
          </div>
        </li>
        <li data-theme="black" [ngClass]="{'actived': selectedBgColor === 'black'}" (click)="selectTheme('black')">
          <div class="black-theme">
            @if(selectedBgColor ==='black'){
              <i class="fa fa-check text-light"></i>
             }
          </div>
        </li>
        <li data-theme="vip" [ngClass]="{'actived': selectedBgColor === 'vip'}" (click)="selectTheme('vip')">
          <div class="vip-theme">
            @if(selectedBgColor ==='vip'){
              <i class="fa fa-check text-light"></i>
             }
          </div>
        </li>
        <!-- <li data-theme="purple" [ngClass]="{'actived': selectedBgColor === 'purple'}" (click)="selectTheme('purple')">
          <div class="purple-theme"></div>
        </li>
        <li data-theme="blue" [ngClass]="{'actived': selectedBgColor === 'blue'}" (click)="selectTheme('blue')">
          <div class="blue-theme"></div>
        </li> -->
        <!-- <li data-theme="cyan" [ngClass]="{'actived': selectedBgColor === 'cyan'}" (click)="selectTheme('cyan')">
          <div class="cyan-theme"></div>
        </li> -->
        <!-- <li data-theme="green" [ngClass]="{'actived': selectedBgColor === 'green'}" (click)="selectTheme('green')">
          <div class="green-theme"></div>
        </li>
        <li data-theme="orange" [ngClass]="{'actived': selectedBgColor === 'orange'}" (click)="selectTheme('orange')">
          <div class="orange-theme"></div>
        </li>
        <li data-theme="red" [ngClass]="{'actived': selectedBgColor === 'red'}" (click)="selectTheme('red')">
          <div class="red-theme"></div>
        </li> -->
      </ul>
    </div>

  </div>
</aside>
}

<!-- #END# Right Sidebar -->