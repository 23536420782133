<!-- Left Sidebar -->
<aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}">
        <ng-scrollbar #scrollable track="vertical" visibility="hover" [disabled]="false" [autoHeightDisabled]="false">
            <ul id="sidebarnav" class="list mb-0">
                <li class="sidebar-user-panel">
                    <div class="user-panel p-3 d-flex justify-content-center my-1">
                        <!-- c by I -->
                        @if (MainSetting | async; as setting) {
                        <img routerLink="/dashboard" src="{{setting?.Logo || setting?.AppLogo}}" class="image-site"
                            alt="Site Image" width="100%" height="auto">
                        }@else {
                        <span routerLink="/dashboard" class="acroym">
                            <span class="mx-auto fw-bold fs-2">
                                Logo
                            </span>
                        </span>
                        }
                    </div>
                </li>
                @if (user?.Organizations?.length && user?.UserType !== 3) {
                <li>
                    <div class="header d-flex align-items-center justify-content-between"
                        [ngClass]="showList?'org-menu':'' " mat-icon-button aria-label="organizations">
                        <!-- <span style="font-size: 14px;letter-spacing: 0.8px;">
                            {{OrganizationName?.length > 30 ? (OrganizationName | slice:0:50)+'...': OrganizationName }}
                        </span> -->
                        <span style="font-size: 14px; letter-spacing: 0.8px; text-align: center;">
                            {{OrganizationName}}
                        </span>
                        @if (user?.Organizations?.length > 1 || !level1Org) {
                        <i [matMenuTriggerFor]="menu" class="fa fa-list show-cursor"></i>
                        <mat-menu class="w-100 mx-5" #menu="matMenu">
                            <h4 class="px-3 fs-6 mb-0">Switch Organization</h4>
                            @for (org of user?.Organizations; track $index) {
                            <button (click)="ToogleOrganization(org)" class="btn btn-square" mat-menu-item>
                                @if (org.Name == OrganizationName) {
                                <span class="dot"></span>
                                }
                                <span>{{org.Name}}</span>
                            </button>

                            }

                        </mat-menu>
                        }
                    </div>
                </li>
                }
                    
                @if(user?.UserType === 1){
                <li>
                    <a (click)="SwitchToAdmin()" class="menu-top show-cursor">
                        <i [ngClass]="['fas fa-user']"></i>
                        <span class="hide-menu">{{'Switch to Admin' }}
                        </span>
                    </a>
                </li>
                }
      
               
                @if (user?.UserType === 1 && level1Menu !=='public-org') {
                <li [class.active]="level1Menu === 'public-org'">
                    <a (click)="NavigateToProfile()" class="menu-top show-cursor">
                        <i [ngClass]="['fas fa-industry']"></i>
                        <span class="hide-menu">{{'Organization Profile' }}
                        </span>
                    </a>
                </li>
                }

                @if (user.UserType !== 1) {
                <li [class.active]="level1Menu === 'dashboard'" routerLinkActive="active">
                    <a href="#" onclick="return" routerLink="/dashboard" (click)="callLevel1Toggle($event, 'dashboard')"
                        class="menu-top">
                        <i class="fa fa-home"></i>
                        <span class="hide-menu">Home</span>
                    </a>
                </li>
                @if(true){
                    <li>
                        <a href="#" onclick="return" routerLink="/search/organizations" (click)="callLevel1Toggle($event, 'users')"
                class="menu-top">
                <i [ngClass]="'fa fa-search'"></i>
                <span class="hide-menu">
                  Search
                </span>
                
              </a>
                    </li>
                    }
                    @if(true){
                        <li>
                            <a href="#" onclick="return" routerLink="/opportunity/p-opportunity-listing" (click)="callLevel1Toggle($event, 'users')"
                    class="menu-top">
                    <i [ngClass]="'fa fa-star'"></i>
                    <span class="hide-menu">
                      Active Opportunities
                    </span>
                    
                  </a>
                        </li>
                        }
                <li [class.active]="level2Menu === 'organization-listing'" routerLinkActive="active">
                    <a href="#" onclick="return" routerLink="/organization/organization-listing"
                        (click)="callLevel2Toggle($event, 'organization-listing')" class="menu-top">
                        <i class="fa fa-cog"></i>
                        <span class="hide-menu">Edit Company Profile</span>
                    </a>
                </li>
                }

                <!-- side-items -->
                @for (item of orgSideItems | async; track $index) {
                <li [class.active]="item.activeLevel? level2Menu === item.module: level1Menu === item.module"
                    routerLinkActive="active">
                    <a href="#" onclick="return" [routerLink]="item.path"
                        (click)="callLevel1Toggle($event, item.module)" class="menu-top" [ngClass]="item.linkClass"
                        routerLinkActive="active">
                        <i [ngClass]="[item.icon]"></i>
                        <span class="hide-menu">{{item.customLabel}}
                        </span>
                        @if (item?.Count || item?.Count ==0 && !item.sub_items?.length) {
                        <span class="badge hide-menu btn-primary pull-right">{{item?.Count ?? 0}}</span>
                        }

                    </a>
                    <ul class="ml-menu">
                        @for (sub of item.sub_items; track $index) {
                        <li [class.activeSub]="level2Menu === sub.level2Menu" routerLinkActive="active">
                            <a href="#" onclick="return" [routerLink]="sub.path"
                                (click)="callLevel2Toggle($event,item.module)">
                                <i [ngClass]="[sub.icon]"></i>
                                <span class="hide-menu">{{sub?.customLabel}}
                                </span>
                                @if (sub?.Count) {
                                <span class="badge btn-primary hide-menu pull-right">{{sub?.Count}}</span>
                                }
                            </a>
                        </li>

                        }

                    </ul>
                    
                </li>
                }
                <div class="advertisements mt-2">
                    <app-slider [actions]="false" [type]="'left'"></app-slider>
                </div>
            </ul>
        </ng-scrollbar>
    </div>
    <!-- #Menu -->
</aside>
<!-- #END# Left Sidebar -->