<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu"
      [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}">
      <ng-scrollbar #scrollable track="vertical" visibility="hover" [disabled]="false" [autoHeightDisabled]="false">
        <ul id="sidebarnav" class="list"
          [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}">

          @if (MainSetting | async; as setting) {
          <li class="sidebar-user-panel">
            <div class="user-panel p-3 d-flex justify-content-center my-3">
              <!-- c by I -->
              @if (setting.Logo || setting.AppLogo) {
              <img routerLink="/dashboard" src="{{setting.Logo || setting.AppLogo}}" class="image-site" alt="Site Image"
                width="100" height="auto">
              }@else {
              <a routerLink="/dashboard" class="acroym">
                <span class="mx-auto fw-bold fs-2">
                  Logo
                </span>
              </a>
              }
            </div>
          </li>
          @if (!setting?.SignUp) {
          <li [class.active]="level1Menu === 'users' && level2Menu === 'invite-signup' " [routerLinkActive]="'active'">
            <a href="#" onclick="return" routerLink="/users/invite-signup" (click)="callLevel1Toggle($event, 'users')"
              class="menu-top">
              <i [ngClass]="'fa fa-user'"></i>
              <span class="hide-menu">
                Invite to SignUp 
              </span>
              
            </a>
          </li>
          }
            } 
          @for (sidebarItem of sidebarItems; track $index) {
          <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
            [routerLinkActive]="sidebarItem.submenu.length != 0  ? '' : 'active'">
           
            @if (sidebarItem.groupTitle) {
            <div>{{sidebarItem.title}}</div>
            }@else {
            <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" [ngClass]="[sidebarItem.class]"
              (click)="callLevel1Toggle($event, sidebarItem.moduleName)" class="menu-top">
              <i [ngClass]="[sidebarItem.icon]">{{sidebarItem?.moduleName == 'analytics'? '&#xf080;':''}}</i>
              <span class="hide-menu">
                {{sidebarItem?.title}}
              </span>

            </a>
            
            }
           
            <!-- First level menu -->
            @if (sidebarItem.submenu.length > 0) {
            <ul class="ml-menu">
              @for (sidebarSubItem of sidebarItem.submenu; track $index) {
              <li [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''" routerLinkActive="active">
                <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                  (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                  {{sidebarSubItem.title }}
                </a>

              </li>
              }
            </ul>
            }
          </li>
          }

        </ul>
      </ng-scrollbar>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>