<nav #navbar class="navbar" [ngClass]="isNavbarShow ? 'active' : 'active'">
    <div class="container-fluid">

        <div class="navbar-header">
            <a class="navbar-toggle collapsed round btn rounded-circle" aria-label="collapse navbar"
                (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false">
                <i class="fa fa-2x" [ngClass]="isNavbarCollapsed ? ' fa-angle-double-down':'f fa-angle-double-up'"></i>
            </a>
            <a (click)="mobileMenuSidebarOpen($event,'overlay-open')" class="round bars btn rounded-circle"
                aria-label="collapse sidebar">
                <i class="fa fa-angle-double-right fa-2x"></i>
            </a>
        </div>

        <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
            <ul class="pull-left collapse-menu-icon d-flex">
                <li class="menuBtn">
                    <button (click)="callSidemenuCollapse()"
                        class="sidemenu-collapse nav-notification-icons round btn rounded-circle p-0"
                        aria-label="Sidebar collapse">
                        <i class="fa fa-2x"
                            [ngClass]="menuClosed ? ' fa-angle-double-right':'f fa-angle-double-left'"></i>
                    </button>
                </li>
                <li>
                    <span class="m-2 fs-5">Welcome Back
                        {{userName}}!</span>
                </li>
            </ul>

            <ul class="nav navbar-nav navbar-right">
                @for (item of navItems | async; track $index) {
                <li class="nav-item ms-1" [ngClass]="{'dropdown':item.class}">
                    <a href="#" onclick="return" class="nav-link show-cursor"
                        [ngClass]="currentURL?.includes(item?.activeClass)? 'currentActive':'' "
                        [routerLink]="item.path?item.path:null" routerLinkActive="router-link-active">
                        {{item?.label || item?.control}}
                    </a>

                    @if (item.class=='dropdown') {
                    @switch (item.control) {
                    @case ('Search') {
                    <div class="dropdown-content">
                        @for (sub of item.subControls; track $index) {
                        <a href="#" onclick="return" [routerLink]="sub.path" class="btn-link w-100 text-center">
                            {{sub?.label || sub?.control}}
                        </a>
                        }
                    </div>
                    }
                    @case ('Forums') {
                    <div class="dropdown-content">
                        @for (forum of ForumsList | async; track $index) {
                        <a href="#" onclick="return" class="btn-link w-100 show-cursor" aria-label="Forums"
                            routerLink="/forums/general-forums"
                            [queryParams]="{ForumCategory:forum?.ForumsCategoryId, ForumCategoryName:forum?.Name}">
                            {{forum?.Name}}
                        </a>
                        }
                    </div>
                    }
                    }
                    }
                </li>
                }


                <li class="nav-item user_profile" ngbDropdown>
                    <app-profile-panel></app-profile-panel>
                </li>
                <li class="nav-item user_profile" ngbDropdown>
                    <button class="btn rounded-circle" (click)="layOutService.visible = true" id="settingBtn">
                        <i class="fa fa-cog"></i>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</nav>