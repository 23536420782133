import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@core/service/auth.service";
import { environment } from "environments/environment";
import { catchError, throwError } from "rxjs";

export const httpAuthInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
      const token = inject(AuthService)?.LoggedUserValue?.Token;
      const _cr = req.clone({
            setHeaders: {
                  Authorization: `Bearer ${token ? token : ''}`,
                  tenant: environment.ClientID
            },
      })
      return next(_cr);
};

export const httpErrorInterceptor: HttpInterceptorFn = (
      req: HttpRequest<unknown>,
      next: HttpHandlerFn,
) => {
      const authService = inject(AuthService);
      const router = inject(Router)
      return next(req).pipe(
            catchError((error: HttpErrorResponse) => {
                  if (error.status === 401 || error.status == 407) {
                        authService.logout();
                        const removeItems = ['loggedUser', 'UserID', 'orgId', 'orgType', 'OrgName', 'ParentOrgID', 'userType'];
                        removeItems.forEach((item: string) => localStorage.removeItem(item))
                        router.navigate(['/authentication/signin'], { queryParams: { state: 'authorize' } })
                  }
                  
                  const err = error?.message || error?.statusText;
                  return throwError(error);
            }),
      );
};