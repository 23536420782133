import { Observable, Subject, map } from "rxjs";
import { ElementRef, Renderer2, inject } from "@angular/core";
import { BootstrapService } from "@core/service/bootstrap.service";
import { MainSettingsModel } from "app/website-settings/models/main-settings-model";

export class SidebarBase{
      protected level1Menu = '';
      protected level2Menu = '';
      protected level3Menu = '';
      protected innerHeight: any;
      protected bodyTag: any;
      protected listMaxHeight: string;
      protected listMaxWidth: string;
      protected unsubscribeAll$: Subject<void> = new Subject();
      protected OrganizationName: string;
      protected Organizations: Array<any> = [];
      protected showList: boolean = false;
      protected renderer = inject(Renderer2);
      protected elementRef = inject(ElementRef)
      protected bootstrapService = inject(BootstrapService);
      protected MainSetting:Observable<MainSettingsModel> = this.bootstrapService.MainSetting.pipe(map((setting) => setting));
      callLevel1Toggle(event: any, element: any) {
            this.level1Menu = element === this.level1Menu ? '0' : element;
            const hasClass = event.target.classList.contains('toggled');
            hasClass ? this.renderer.removeClass(event.target, 'toggled') : this.renderer.addClass(event.target, 'toggled');
        
          }
          callLevel2Toggle(event: any, element: any) {
            this.level2Menu = element === this.level2Menu ? '0' : element;
          }
        
          callLevel3Toggle(event: any, element: any) {
            this.level3Menu = element === this.level3Menu ? '0' : element;
        
          }
        
          initLeftSidebar() {
            const _this = this;
            _this.setMenuHeight();
            _this.checkStatuForResize(true);
          }
        
          setMenuHeight() {
            this.innerHeight = window.innerHeight;
            const height = this.innerHeight;
            this.listMaxHeight = 140 + height + '';
            this.listMaxWidth = '500px';
          }
        
          checkStatuForResize(firstTime: boolean) {
            if (firstTime && window.innerWidth < 1170) {
              this.renderer.addClass(document.body, 'ls-closed');
              return
            }
            if (window.innerWidth < 1170) {
              this.renderer.addClass(document.body, 'ls-closed');
            } else {
              this.renderer.removeClass(document.body, 'ls-closed');
            }
          }
          mouseHover(e) {
            const body = this.elementRef.nativeElement.closest('body');
            if (body.classList.contains('submenu-closed')) {
              this.renderer.addClass(document.body, 'side-closed-hover');
              this.renderer.removeClass(document.body, 'submenu-closed');
              this.showList = true
            }
          }
          mouseOut(e) {
            const body = this.elementRef.nativeElement.closest('body');
            if (body.classList.contains('side-closed-hover')) {
              this.renderer.removeClass(document.body, 'side-closed-hover');
              this.renderer.addClass(document.body, 'submenu-closed');
            }
            this.showList = false
          }

}