import { Component, OnInit, AfterViewInit, OnDestroy, inject } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { LayoutService } from '../services/layout.service';
import { Observable, filter, map, takeUntil } from 'rxjs';
import { HeaderItems } from './header-items';
import { HeaderBase } from '../base/header.base';
import { AuthService } from '@core/service/auth.service';
import { AsyncPipe, NgClass } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { ProfilePanelComponent } from '../profile-panel/profile-panel.component';
import { BootstrapService } from '@core/service/bootstrap.service';
import { IHeaderLink } from './iheader-links';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [AsyncPipe, NgClass, RouterLink, MatIconModule, NgbCollapse, ProfilePanelComponent],
  providers: []
})
export class HeaderComponent extends HeaderBase implements OnInit, AfterViewInit, OnDestroy {
  navItems: Observable<IHeaderLink[]>;
  constructor(
    private authService: AuthService,
    private router: Router,
    public layOutService: LayoutService
  ) {
    super();
    this.userName = this.authService.LoggedUserValue.FirstName;
    this.currentURL = this.router.url;
    this.router.events.pipe(
      filter((events => events instanceof NavigationEnd)), takeUntil(this.Destroy$))
      .subscribe((event: NavigationEnd) => {
        this.currentURL = event?.url
      })
  }


  ngOnDestroy(): void {
    this.Destroy$?.next();
    this.Destroy$?.complete()
  }

  ngOnInit() {
    this.nav_items_init();
    this.FetchForums();
  }

  FetchForums() {
    this.ForumsList = this.layOutService.FetchForumsCatagoryList({}).pipe(map((items: any) => items?.Data))
  }

  ngAfterViewInit() {
    this.theme_init();
  }

  logout() {
    this.RemoveLocalStorageItems(['orgId', 'UserID', 'CommentsEnabled', 'orgType', 'OrgName', 'ParentOrgID', 'loggedUser', 'Countries'])
    this.authService.LoggedUserSubject?.next(null);
    this.authService.userType.next(null);
    this.router.navigate(['/authentication/signin']);
  }

  RemoveLocalStorageItems(Keys: Array<string>) {
    Keys.forEach((key) => localStorage.removeItem(key))
  }

  nav_items_init() {
    this.navItems = this.bootstrapService.MainSetting.pipe(
      map((setting) => {
        this.nav_items = HeaderItems.reduce((filteredItems: IHeaderLink[], item) => {
          if (setting[item.control]) {
            if (item.subControls?.length) {
              item.subControls = item.subControls
                .filter(sc => setting[sc?.control])
                .map(sc => ({ ...sc, label: setting[sc.controlCustomLabel] }));
            }
            filteredItems.push({ ...item, label: setting[item.controlCustomLabel] });
          }
          return filteredItems;
        }, []);

        const dropdown_org_user_item = this.nav_items.find(e => e.hasOptions);
        if (dropdown_org_user_item) {
          const hasItems = setting.Organisation && setting.UserModule ? 'both'
            : setting.Organisation ? 'org'
              : setting.UserModule ? 'user'
                : null;

          switch (hasItems) {
            case 'both':
              dropdown_org_user_item.class = "dropdown";
              break;
            case 'org':
              dropdown_org_user_item.class = "";
              dropdown_org_user_item.path = '/search/organizations';
              break;
            case 'user':
              dropdown_org_user_item.class = "";
              dropdown_org_user_item.path = '/search/users';
              break;
          }
        }
        return this.nav_items;
      })
    );
  }

}
