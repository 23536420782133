import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'HOME',
    moduleName: 'dashboard',
    icon: 'fas fa-home',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '/search/organizations',
    title: 'Search',
    moduleName: 'manage-marketplace',
    icon: 'fas fa-search',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '/opportunity/p-opportunity-listing',
    title: 'Active Opportunities',
    moduleName: 'manage-marketplace',
    icon: 'fas fa-star',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '/manage-marketplace/manage-organizations',
    title: 'Organizations',
    moduleName: 'manage-marketplace',
    icon: 'fas fa-industry',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '/users/consultants',
    title: 'Users',
    moduleName: 'users',
    icon: 'fas fa-users',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '/advertisement',
    title: 'Advertisement',
    moduleName: 'advertisement',
    icon: 'fas fa-ad',
    class: '',
    groupTitle: false,
    submenu: []
  },
  // {
  //   path: '/notification/admin-notifications',
  //   title: 'Notification System',
  //   moduleName: 'notification',
  //   icon: 'fas fa-bell',
  //   class: '',
  //   groupTitle: false,
  //   submenu: []
  // },
  // {
  //   path: '/comments/comments-listing',
  //   title: 'Comments',
  //   moduleName: 'calendar',
  //   icon: 'fas fa-comment',
  //   class: '',
  //   groupTitle: false,
  //   submenu: []
  // },
  {
    path: null,
    title: 'App Settings',
    moduleName: 'website-settings',
    icon: 'fas fa-cog',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/website-settings/setting',
        title: 'App Settings',
        moduleName: 'website-settings',
        icon: 'fas fa-cog',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/website-settings/webinar-listing',
        title: 'Webinar Settings',
        moduleName: 'website-settings',
        icon: 'fas fa-cog',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'website-settings/master-lookup-list',
        title: 'Master Lookup Table Settings',
        moduleName: 'website-settings',
        icon: 'fas fa-th-list',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: null,
    title: 'Marketplace Settings',
    moduleName: 'marketplace',
    icon: 'fas fa-cog',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/marketplace/marketplace-controls',
        title: 'Marketplace controls',
        moduleName: 'marketplace',
        icon: 'fas fa-cubes',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/marketplace/marketplace-types',
        title: 'Marketplace Types',
        moduleName: 'marketplace',
        icon: 'fas fa-cubes',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/marketplace/custom-fields',
        title: 'Custom Fields',
        moduleName: 'marketplace',
        icon: 'fas fa-th-list',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: null,
    title: 'Email',
    moduleName: 'email',
    icon: 'fas fa-envelope',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/email/smtp-list',
        title: 'SMTP',
        moduleName: 'email',
        icon: 'fas fa-cog',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/email/send-email-org-list',
        title: 'Send Emails',
        moduleName: 'email',
        icon: 'fas fa-paper-plane',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/email/templates',
        title: 'Email Templates',
        moduleName: 'email',
        icon: 'fas fa-file',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/email/email-status',
        title: 'Email Status',
        moduleName: 'email',
        icon: 'fas fa-exclamation',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/email/email-digest',
        title: 'Email Digest',
        moduleName: 'email',
        icon: 'fas fa-sticky-note',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: null,
    title: 'Membership',
    moduleName: 'membership',
    icon: 'fas fa-bezier-curve',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/membership/membership-types',
        title: 'Member Types',
        moduleName: 'membership',
        icon: 'fas fa-cubes',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      // {
      //   path: '/membership/member-permissions',
      //   title: 'Member Permissions',
      //   moduleName: 'membership',
      //   icon: 'fas fa-user-shield',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   submenu: []
      // },
      // {
      //   path: '/membership/payment-gateway-setting',
      //   title: 'Payment-Gateway',
      //   moduleName: 'membership',
      //   icon: 'fas fa-user-shield',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   submenu: []
      // }
    ]
  },
  {
    path: null,
    title: 'Location',
    moduleName: 'location-settings',
    icon: 'fas fa-globe',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/location-settings/countries',
        title: 'Countries',
        moduleName: 'location-settings',
        icon: 'fa-flag',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  // {
  //   path: null,
  //   title: 'Import Utility',
  //   moduleName: 'import-utility',
  //   icon: 'fas fa-file-import',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/import-utility/',
  //       title: 'Import Marketplace',
  //       moduleName: 'import-utility',
  //       icon: 'fas fa-file-import',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  {
    path: null,
    title: 'Analytics',
    moduleName: 'analytics',
    icon: 'fa fa-area-chart',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/analytics',
        title: 'Analytics Report',
        moduleName: 'analytics',
        icon: 'fa fa-area-chart',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  // {
  //   path: null,
  //   title: 'Payment Gateway',
  //   moduleName: 'payment',
  //   icon: 'fa fa-credit-card',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: null,
  //       title: 'Payment Gateway Setting',
  //       moduleName: 'payment',
  //       icon: 'fas fa-cog',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },

];
