import { CommonModule, DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  AfterViewInit,
  Renderer2,
  ChangeDetectorRef,
  inject
} from '@angular/core';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { ConfigService } from '@config/config.service';
import { LayoutService } from '../services/layout.service';
import { ThemeService } from '@shared/services/theme.service';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.sass'],
  standalone: true,
  imports: [CommonModule, NgClickOutsideDirective]
})
export class RightSidebarComponent implements OnInit, AfterViewInit {
  layoutService = inject(LayoutService);
  themeService = inject(ThemeService);
  selectedBgColor = 'white';
  maxHeight: string;
  maxWidth: string;
  public config: any = {};
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef
  ) { }


  ngOnInit() {
    this.config = this.configService.configData;
    this.setRightSidebarWindowHeight();
  }
  ngAfterViewInit() {
    // set header color on startup
    if (localStorage.getItem('choose_skin')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_skin')
      );
      this.selectedBgColor = localStorage.getItem('choose_skin_active');
    } else {
      this.renderer.addClass(
        this.document.body,
        'theme-' + this.config.layout.theme_color
      );
      this.selectedBgColor = this.config.layout.theme_color;
    }
    this.cdr.detectChanges()
  }

  selectTheme(e:'white'|'black'|'vip') {
    this.selectedBgColor = e;
    const prevTheme = this.elementRef.nativeElement
      .querySelector('.right-sidebar .demo-choose-skin li.actived')
      .getAttribute('data-theme');
    this.renderer.removeClass(this.document.body, 'theme-' + prevTheme);
    this.renderer.removeClass(this.document.body, 'menu_' + prevTheme);
    this.renderer.addClass(this.document.body, 'theme-' + this.selectedBgColor);
    this.renderer.addClass(this.document.body, 'menu_' + this.selectedBgColor);
    localStorage.setItem('choose_skin', 'theme-' + this.selectedBgColor);
    localStorage.setItem('choose_skin_active', this.selectedBgColor);
    localStorage.setItem('choose_skin', 'menu_' + this.selectedBgColor);
    this.themeService.activeThemeSignal.set(e);
    this.cdr.detectChanges();
  }

  setRightSidebarWindowHeight() {
    const height = window.innerHeight - 137;
    this.maxHeight = height + '';
    this.maxWidth = '500px';
  }
  onClickedOutside(event: Event) {
    const button = event.target as HTMLButtonElement;
    if (button.parentElement.id !== 'settingBtn') {
     this.layoutService.visible = !this.layoutService.visible
    }
  }

}
