import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { authenticationGuard, roleGuard } from '@core/fGuards/authentication.guard';
import { Page404Component } from './authentication/page404/page404.component';
import { Page500Component } from './authentication/page500/page500.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { accessLevel } from '@core/fGuards/access-level.guard';
const routes: Routes = [
  {
    path: 'authentication',
    loadComponent: () => import('./layout/app-layout/auth-layout/auth-layout.component').then(c => c.AuthLayoutComponent),
    loadChildren: () =>
      import('./authentication/authentication.module').then((m) => m.AuthenticationModule)
  },
  {
    path: 'registration',
    loadComponent: () => import('./layout/app-layout/auth-layout/auth-layout.component').then(c => c.AuthLayoutComponent),
    loadChildren: () =>
      import('./registration/registration.module').then((m) => m.RegistrationModule)
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        canActivate: [authenticationGuard],
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'email',
        canActivate: [roleGuard],
        loadChildren: () =>
          import('./email/email.module').then((m) => m.EmailModule)
      },
      {
        path: 'organization',
        loadChildren: () =>
          import('./organization/organization.module').then((m) => m.OrganizationModule)
      },
      // {
      //   path: 'public-org',
      //   loadChildren: () =>
      //     import('./public-org/public-org.module').then((m) => m.PublicOrgModule)
      // },
      {
        path: 'opportunity',
        loadChildren: () =>
          import('./opportunity/opportunity.module').then((m) => m.OpportunityModule)
      },
      {
        path: 'calendar',
        loadChildren: () =>
          import('./calendar/calendar.module').then((m) => m.CalendarModule)
      },
      {
        path: 'donation',
        loadChildren: () =>
          import('./donation/donation.module').then((m) => m.DonationModule)
      },
      {
        path: 'forums',
        loadChildren: () =>
          import('./forums/forums.module').then((m) => m.ForumsModule)
      },
      {
        path: 'classes',
        loadChildren: () =>
          import('./classes/classes.module').then((m) => m.ClassesModule)
      },
      {
        path: 'comments',
        loadChildren: () =>
          import('./comments/comments.module').then((m) => m.CommentsModule)
      },
      {
        path: 'notification',
        loadChildren: () =>
          import('./notification-system/notification-system.module').then((m) => m.NotificationSystemModule)
      },
      {
        path: 'services',
        loadChildren: () =>
          import('./services/services.module').then((m) => m.ServicesModule)
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./users-viewers/users-viewers.module').then((m) => m.UsersViewersModule)
      },
      {
        path: 'documents',
        canActivate: [authenticationGuard],
        loadChildren: () =>
          import('./documents/documents.module').then((m) => m.DocumentsModule)
      },
      {
        path: 'analytics',
        canActivate: [roleGuard],
        loadChildren: () =>
          import('./analytics/analytics.module').then((m) => m.AnalyticsModule)
      },
      {
        path: 'advertisement',
        canActivate: [roleGuard],
        loadChildren: () =>
          import('./slider-settings/slider-settings.module').then((m) => m.SliderSettingsModule)
      },
      {
        path: 'location-settings',
        canActivate: [roleGuard],
        loadChildren: () =>
          import('./location/location.module').then((m) => m.LocationModule)
      },
      {
        path: 'membership',
        canActivate: [roleGuard],
        loadChildren: () =>
          import('./membership/membership.module').then((m) => m.MembershipModule)
      },
      {
        path: 'website-settings',
        canActivate: [roleGuard],
        loadChildren: () =>
          import('./website-settings/website-settings.module').then((m) => m.WebsiteSettingsModule)
      },
      {
        path: 'marketplace',
        loadChildren: () =>
          import('./matketplace/matketplace.module').then((m) => m.MatketplaceModule)
      },
      {
        path: 'manage-marketplace',
        canActivate: [roleGuard],
        loadChildren: () =>
          import('./manage-marketplace/manage-marketplace.module').then((m) => m.ManageVipMarketplaceModule)
      },
      {
        path: 'payment',
        canActivate: [authenticationGuard],
        loadChildren: () =>
          import('./payment/payment.module').then((m) => m.PaymentModule)
      },
      {
        path: 'saved-search',
        canActivate: [authenticationGuard],
        loadChildren: () =>
          import('./saved-search/saved-search.module').then((m) => m.SavedSearchModule)
      },
      {
        path: 'search',
        canActivate:[accessLevel('SearchPrivacy')],
        loadChildren: async () => (await import('./search/search.module')).SearchModule
      }
    ]
  },
  { path: '403', component: Page500Component },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top", bindToComponentInputs:true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
