<a [matMenuTriggerFor]="menu" #menuTrigger role="button" class="nav-notification-icons pt-0">
      <span class="rounded-circle img-circle-header user-img-circle bg-light acroym text-center">
            <span class="mx-auto fw-bold fs-5 text-dark">
                  {{User?.FirstName[0] + ' ' +User?.LastName[0]
                  | acroym:true}}
            </span>
      </span>
</a>
<mat-menu #menu="matMenu">
      <div class="body p-3">
            <div class="row">
                  <div class="col-lg-12 text-secondary d-flex align-items-center justify-content-between">
                        {{SiteName}}
                        <button (click)="signOut()" class="sign-out btn fs-small">
                              Sign Out
                        </button>
                  </div>
            </div>
            <div class="row d-flex align-items-center justify-content-center">
                  <div class="col-lg-4">
                        @if (User.Picture) {
                        <img (error)="User.Picture = ''" src="{{User?.Picture | pathModifier}}"
                              class="profile-image-xxs" alt="Image" height="auto" width="100%" />
                        }@else {

                        <span class="img-circle acroym btn-primary img-circle-css text-center my-auto msr-3">
                              <span class="mx-auto fw-bold fs-2">
                                    {{ (User?.FirstName + ' ' + User?.LastName) | acroym}}
                              </span>
                        </span>
                        }
                  </div>
                  <div class="col-lg-8 p-3">
                        <div class="header">
                              <h4 class="mb-0 text-theme">
                                    <strong> {{User?.FirstName + ' '+ User?.LastName}}</strong>
                              </h4>
                        </div>

                        <span>{{User?.EmailAddress}}</span>
                        <br>
                        <a onclick="return" aria-label="profile" routerLink="/users/profile/{{User.UsersID}}">View
                              Profile</a>
                  </div>
            </div>
      </div>
</mat-menu>