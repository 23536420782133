export interface IMenuItem{
label:string;
customLabel:string;
Ref:string;
module:string;
path:string;
Count:number;
icon:string;
activeLevel:number;
linkClass?:string;
level2Menu?:string;
sub_items?:Array<IMenuItem>;
default?:boolean,
disableForAdmin?:boolean
}

export const menu_items:Array<IMenuItem>=[
      {
            label:'Users',
            customLabel:null,
            Ref:'User',
            Count:0,
            path:'/users/users-viewers',
            module:'users-viewers',
            icon:'fa fa-user',
            activeLevel:1,
            default:true
      },
      {
            label:'SubOrganizations',
            customLabel:'SubOrganizationCustomLabel',
            Ref:'Organization',
            Count:0,
            path:'/organization/sub-organization-listing',
            module:'sub-organization-listing',
            icon:'fa fa-industry',
            activeLevel:1
      },
      {
            label:'Opportunity',
            customLabel:'OpportunityCustomLabel',
            Ref:'Opportunity',
            Count:0,
            path:'/opportunity/opportunity-listing',
            module:'opportunity',
            icon:'fa fa-star',
            activeLevel:0
      },
      {
            label:'Donation',
            customLabel:'DonationCustomLabel',
            Ref:'Donation',
            Count:0,
            path:'/donation/donations-listing',
            module:'donation',
            icon:'fa fa-donate',
            activeLevel:0
      },
      {
            label:'Services',
            customLabel:'ServiceCustomLabel',
            Ref:'Service',
            Count:0,
            path:'/services/services-listing',
            module:'services',
            icon:'fa fa-wrench',
            activeLevel:0
      },
      {
            label:'Forums',
            customLabel:'ForumsLabel',
            Ref:'Forum',
            Count:0,
            path:'/forums/forums-listing',
            module:'forums',
            icon:'fa fa-calendar',
            activeLevel:0
      },
      {
            label:'Classes',
            customLabel:'ClassesCustomLabel',
            Ref:'Class',
            Count:0,
            path:'/classes/classes-listing',
            module:'classes',
            icon:'fa fa-chalkboard',
            activeLevel:0
      },
      {
            label:'NotificationSystem',
            customLabel:'NotificationSystemCustomLabel',
            Ref:'Notification',
            Count:0,
            path:'/notification/notifications-listing',
            module:'notification',
            icon:'fa fa-bell',
            activeLevel:0
      },
      {
            label:'Document',
            customLabel:'DocumentLabel',
            Ref:'Document',
            Count:0,
            path:'/documents/documents-listing',
            module:'documents-listing',
            icon:'fa fa-file',
            activeLevel:1
      },
      {
            label:'CapabilityStatement',
            customLabel:'CapabilityStatement',
            Ref:'CapabilityStatement',
            Count:0,
            path:'/documents/capability-statement',
            module:'capability-statement',
            icon:'fa fa-file',
            activeLevel:1
      },
      {
            label:'CalenderOfEvents',
            customLabel:'COECustomLabel',
            Ref:"Calendar",
            path:null,
            Count:0,
            module:'calendar',
            linkClass:'menu-toggle',
            icon:'fa fa-calendar',
            activeLevel:0,
            sub_items:[
                  {
                        label:'Activities',
                        customLabel:'ActivitiesCustomLabel',
                        Ref:'Activity',
                        Count:0,
                        path:'/calendar/organization-activities',
                        module:'calendar',
                        level2Menu:'organization-activities',
                        icon:'fa fa-tasks',
                        activeLevel:1,    
                  },
                  {
                        label:'Events',
                        customLabel:'EventsCustomLabel',
                        Ref:'Event',
                        Count:0,
                        path:'/calendar/organization-events',
                        module:'calendar',
                        level2Menu:'organization-events',
                        icon:'fa fa-calendar',
                        activeLevel:1,    
                  },
                  {
                        label:'Posting',
                        customLabel:'PostingCustomLabel',
                        Ref:'Posting',
                        Count:0,
                        path:'/calendar/organization-postings',
                        module:'calendar',
                        level2Menu:'organization-postings',
                        icon:'fa fa-sticky-note',
                        activeLevel:1,    
                  }
            ]
      },
      // {
      //       label:'Payment Gateways',
      //       customLabel:null,
      //       Ref:null,
      //       path:'/payment/payment-gateways',
      //       module:'payment',
      //       icon:'fa fa-credit-card',
      //       activeLevel:0,
      //       default:true
      // },
]