import { Router, NavigationEnd, RouterLink, RouterLinkActive } from '@angular/router';
import {
  Component,
  OnInit,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { ROUTES } from './sidebar-items';
import { filter, takeUntil } from 'rxjs';
import { SidebarBase } from '../base/sidebar.base';
import { RouteInfo } from './sidebar.metadata';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss'],
  standalone: true,
  imports: [NgClass, NgStyle, NgScrollbar,
    MatIconModule, AsyncPipe, RouterLink,
    RouterLinkActive]
})
export class AdminSidebarComponent extends SidebarBase implements OnInit, OnDestroy {
  sidebarItems: Array<RouteInfo> = []
  constructor(
    private router: Router
  ) {
    super();
    this.router.events.pipe(filter((ev) => ev instanceof NavigationEnd), takeUntil(this.unsubscribeAll$)).subscribe((event: NavigationEnd) => {
      const currenturl = event.url.split('?')[0];
      this.level1Menu = currenturl.split('/')[1];
      this.level2Menu = currenturl.split('/')[2];
      this.renderer.removeClass(document.body, 'overlay-open');
    });
  }

  ngOnInit() {
    this.initLeftSidebar()
    this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);
  }

  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(document.body, 'overlay-open');
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll$?.next();
    this.unsubscribeAll$?.complete()
  }

}
