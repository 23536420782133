<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu"
      [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}">
      <ng-scrollbar #scrollable track="vertical" visibility="hover" [disabled]="false" [autoHeightDisabled]="false">
        <ul id="sidebarnav" class="list"
          [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}">
          <li class="sidebar-user-panel">
            <div class="user-panel p-3 d-flex justify-content-center my-1">
              <!-- c by I -->
              @if (MainSetting | async; as setting) {
              <img routerLink="/dashboard" src="{{setting?.Logo || setting?.AppLogo}}" class="image-site"
                alt="Site Image" width="100%" height="auto">
              }@else {
              <span routerLink="/dashboard" class="acroym">
                <span class="mx-auto fw-bold fs-2">
                  Logo
                </span>
              </span>
              }
            </div>
          </li>
          <!-- Top Most level menu -->
          @for (item of sidebarItems; track $index) {
          <li [class.active]="item.activeLevel? level2Menu === item.module: level1Menu === item.module"
            routerLinkActive="active">
            @if (item?.default) {
            <a href="#" onclick="return" [routerLink]="[item.path]" (click)="callLevel1Toggle($event, item.module)"
              class="menu-top">
              <i [ngClass]="[item.icon]"></i>
              <span class="hide-menu">{{item.label}}
              </span>
            </a>
            }@else {
            <a href="#" onclick="return" [routerLink]="item.path" (click)="callLevel1Toggle($event, item.module)"
              class="menu-top" [ngClass]="item.linkClass" routerLinkActive="active">
              <i [ngClass]="[item.icon]"></i>
              <span class="hide-menu">{{wesbiteSetting[item.customLabel] }}
              </span>
            </a>
            @if (item?.submenu?.length) {
            <ul class="ml-menu">
              @for (sub of item.submenu; track $index) {
              <li [ngClass]="level2Menu === sub.level2Menu ? 'activeSub': '' " routerLinkActive="active">
                <a href="#" onclick="return" [routerLink]="sub.path" (click)="callLevel2Toggle($event,item.module)">
                  <i [ngClass]="[sub.icon]"></i>
                  <span class="hide-menu">{{wesbiteSetting[sub?.customLabel] }}
                  </span>
                </a>
              </li>
              }

            </ul>
            }

            }

          </li>
          }

        </ul>
      </ng-scrollbar>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>