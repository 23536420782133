
import { Component, HostListener, OnInit} from '@angular/core';
import { Router, NavigationEnd, RouterLink } from '@angular/router';
import { takeUntil } from 'rxjs';
import { SidebarBase } from '../base/sidebar.base';
import { IMenuItem, menu_items } from './individual_menu_items';
import { NgStyle, NgClass, NgIf, NgFor, SlicePipe, AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatDividerModule } from '@angular/material/divider';
import { MainSettingsModel } from 'app/website-settings/models/main-settings-model';

@Component({
  selector: 'app-individual-sidebar',
  templateUrl: './individual-sidebar.component.html',
  styleUrls: ['./individual-sidebar.component.scss'],
  standalone: true,
  imports: [NgStyle, NgClass, NgIf, NgFor, SlicePipe, AsyncPipe, NgScrollbarModule, MatDividerModule, MatMenuModule, MatIconModule, RouterLink]
})
export class IndividualSidebarComponent extends SidebarBase implements OnInit {
  sidebarItems: Array<IMenuItem> = [];
  wesbiteSetting:MainSettingsModel = this.bootstrapService.WebsiteSetting;
  constructor(
    private router: Router
  ) {
    super();
    this.router.events.pipe(takeUntil(this.unsubscribeAll$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currenturl = event.url.split('?')[0];
        this.level1Menu = currenturl.split('/')[1];
        this.level2Menu = currenturl.split('/')[2];
        this.renderer.removeClass(document.body, 'overlay-open');
      }
    });
  }

  ngOnInit() {
    this.initLeftSidebar();
    this.items_init()
  }

  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(document.body, 'overlay-open');
    }
  }

  items_init() {
    this.sidebarItems = menu_items.filter((item) => {
      if (item.default || this.wesbiteSetting[item.label]) {
        return true
      }
      return true
    })
    console.log(this.sidebarItems);
    
  }

  ngOnDestroy() {
    this.unsubscribeAll$?.next();
    this.unsubscribeAll$?.complete()
  }

}
