import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '@core/service/auth.service';
import { AdminSidebarComponent } from 'app/layout/admin-sidebar/admin-sidebar.component';
import { HeaderComponent } from 'app/layout/header/header.component';
import { IndividualSidebarComponent } from 'app/layout/individual-sidebar/individual-sidebar.component';
import { RightSidebarComponent } from 'app/layout/right-sidebar/right-sidebar.component';
import { SidebarComponent } from 'app/layout/sidebar/sidebar.component';
import { HeaderPublicComponent } from '../auth-layout/header-public/header-public.component';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  standalone: true,
  imports: [NgIf, RouterOutlet, AsyncPipe, HeaderComponent, SidebarComponent, AdminSidebarComponent, IndividualSidebarComponent, RightSidebarComponent, HeaderPublicComponent]
})
export class MainLayoutComponent implements OnInit {
  authService = inject(AuthService);
  userType: Number = this.authService?.LoggedUserValue?.UserType || 0;
  ngOnInit(): void { }

}
