import { NgIf } from '@angular/common';
import { Component, Input, NgZone, OnInit, ViewChild, inject } from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { UserInformation } from '@core/models/user-information';
import { AuthService } from '@core/service/auth.service';
import { BootstrapService } from '@core/service/bootstrap.service';
import { CommonServicesService } from '@core/service/common-services.service';
import { AcroymPipe } from '@shared/pipes/acroym.pipe';
import { PathModifierPipe } from '@shared/pipes/path-modifier.pipe';
import { tap } from 'rxjs';

@Component({
  selector: 'app-profile-panel',
  templateUrl: './profile-panel.component.html',
  styleUrls: ['./profile-panel.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink, PathModifierPipe, MatMenuModule, AcroymPipe]
})
export class ProfilePanelComponent implements OnInit {

  @ViewChild(MatMenuTrigger, { static: false }) profilePanel: MatMenuTrigger
  User: UserInformation = JSON.parse(localStorage.getItem('loggedUser'));
  SiteName:string = inject(BootstrapService).SiteName;
  constructor(private authService: AuthService, private router: Router,
    private commonService: CommonServicesService, private ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.commonService.getUpdateUser().pipe(tap((result: any) => {
      if (result) {
        this.User = JSON.parse(localStorage.getItem('loggedUser'))
      }
    })).subscribe()
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('scroll', (ev) => {
        if (this.profilePanel?.menuOpen) {
          this.profilePanel?.closeMenu();
        }
      })
    })
  }
  signOut() {
    this.RemoveLocalStorageItems(['orgId', 'UserID', 'CommentsEnabled', 'orgType', 'OrgName', 'ParentOrgID', 'loggedUser', 'userType'])
    this.authService.LoggedUserSubject?.next(null);
    this.authService.userType.next(null);
    this.router.navigate(['/authentication/signin']);
  }

  RemoveLocalStorageItems(Keys: Array<any>) {
    Keys.forEach((key) => localStorage.removeItem(key))
  }

}
