<div class="lock">
  <div class="message text-center">
    <div class="mb-3 text-center">
      <i class="fa fa-lock fa-8x"></i>
    </div>
    <h1>Access to this page is restricted</h1>
    <p class="fs-5">Please check with the site admin if you believe this is a mistake.</p>
    <button class="btn btn-lg btn-primary fs-4" (click)="submit()">
      Sign In
    </button>
  </div>
</div>